import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useMemo } from 'react';
import { PreviewArticleType } from '../../utils/blogTypes';
import ArticlePreview from '../ArticlePreview';
import Button from '../Button';
import Text from '../Text';
import './style.scss';

type LatestArticlesListProps = {
  className?: string;
};

const LatestArticlesList: React.FC<LatestArticlesListProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  const {
    allSanityArticle: { nodes: articles },
  } = useStaticQuery<{
    allSanityArticle: { nodes: PreviewArticleType[] };
  }>(graphql`
    query {
      allSanityArticle(sort: { order: DESC, fields: _createdAt }, limit: 3) {
        nodes {
          ...ListArticle
          headerImage {
            mainImage {
              asset {
                gatsbyImageData(
                  height: 210
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            altText
            caption
          }
        }
      }
    }
  `);

  const Articles = useMemo(() => {
    return articles.map(article => (
      <ArticlePreview {...article} key={article.id} />
    ));
  }, [articles]);

  return (
    <div className={`latest-articles ${className}`}>
      <div className="latest-articles__header latest-artilces-list-header">
        <Text type="p" className="latest-artilces-list-header__title">
          {t('posts_list_title')}
        </Text>
        <Button color="transparent" outlined size="medium" href="/blog/">
          <Text type="p" className="latest-artilces-list-header__button-text">
            {t('all_posts')}
          </Text>
        </Button>
      </div>
      <div className="latest-articles-list">{Articles}</div>
    </div>
  );
};

export default React.memo(LatestArticlesList);
