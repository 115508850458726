import * as React from 'react';
import { ResponseState } from '../../hooks/useContactForm';
import './style.scss';
import ContactForm from '../ContactForm';
import LatestArticlesList from '../LatestArticlesList';
import { useCallback } from 'react';

const ChallengeUsPage: React.SFC<{
  setRequestResponse?: (state: ResponseState) => void;
}> = () => {
  const setDataLayerEvent = useCallback(
    () => window?.dataLayer?.push?.({ event: 'contact_us_form_success' }),
    []
  );

  return (
    <div className={'challenge-us-page'}>
      <ContactForm
        className="challenge-us-page__form"
        onSuccess={setDataLayerEvent}
      />

      <LatestArticlesList />
    </div>
  );
};

export default ChallengeUsPage;
